import React, { useState, useEffect, useCallback } from 'react'
import { Router } from 'react-router-dom'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import { Paper } from '@material-ui/core'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom'
import Routes from './routes'
import history from './services/history'
import UserService from './services/UserService'
import api from './services/api'
import theme from './utils/theme'

import {
  saveSelectedProfile,
  getSelectedProfile,
  removeSelectedProfile,
} from './utils/user/profileHelper'
import PROFILES from './shared/profiles'
import { ModalDialog, LoadingModal, Footer, Navbar, CustomSnackbar, HelpButton } from './components'

import {
  SnackbarContextProvider,
  ApplicationTypeContextProvider,
  FilterContextProvider,
  FilterItemKeyContextProvider,
  LoadingContextProvider,
  ModalContextProvider,
  AuthContext,
  SidebarProvider,
  ReportTypeContextProvider,
  NetworkManagerProvider,
  HeaderContextProvider,
} from './contexts'
import { useResponsiveInfo } from './shared/hooks'
import { HttpStatus } from './utils/HttpStatus'
import FtdService from './services/FtdService'
import { ImportTrackingProvider } from './contexts/ImportTrackingContext/ImportTrackingContext'
import TermsAndPolicyDialog from './components/TermsAndPolicyDialog/TermsAndPolicyDialog'
import {
  handleAcceptFtdTermsAndPolicy,
  verifyFtdFirstLogin,
} from './utils/ftd/FtdTermsAndPolicyUtils'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: 'calc(100vh - 338px)',
    overflow: 'hidden',
    margin: 0,
    paddingTop: '115px',
  },
  rootLogged: {
    display: 'flex',
    borderRadius: 0,
    overflow: 'hidden',
    margin: 0,
    paddingTop: '119px',
    minHeight: 'calc(100vh - 150px)',
    paddingBottom: '50px',
  },

  childrenContainer: {
    width: '100%',
    margin: '38px 60px 90px 60px',
  },
  childrenContainerNoMargin: {
    width: '100%',
  },
}))

const App = () => {
  const classes = useStyles()
  const [signedIn, setSignedIn] = useState(false)
  const [signedInPlatform, setSignedInPlatform] = useState(false)
  const [currentUser, setCurrentUser] = useState(undefined)
  const [isSpecialNetworkManager, setIsSpecialNetworkManager] = useState(undefined)
  const [isNetworkManagerAt, setIsNetworkManagerAt] = useState(undefined)
  const [isAdmFarol, setIsAdmFarol] = useState(undefined)
  const [isAdmFtd, setIsAdmFtd] = useState(undefined)
  const [activeAssessmentsInfo, setActiveAssessmentsInfo] = useState(undefined)
  const [
    partCollaboratorHasStartedAssessments,
    setPartCollaboratorHasStartedAssessments,
  ] = useState(undefined)
  const [isHideNavBar, setIsHideNavBar] = useState(undefined)
  const [isHideFooter, setIsHideFooter] = useState(undefined)
  const [closeDrawerMenu, setCloseDrawerMenu] = useState(false)
  const [open, setOpen] = React.useState(false)
  const { isDesktop } = useResponsiveInfo()
  const [isConexia, setIsConexia] = useState(undefined)

  const environment = process.env.REACT_APP_ENV
  const isRealizandoEscolhas =
    window.location.pathname && window.location.pathname.indexOf('/realizando-escolhas') === 0
  const [isTermsAndPolicyOpen, setIsTermsAndPolicyOpen] = useState(false)
  const setValues = event => {
    const { profile } = event.data
    if (profile) saveSelectedProfile(profile)
  }

  function handleProfile(profile) {
    if (!UserService.hasRole([profile.role])) {
      removeSelectedProfile()
      history.push({
        pathname: '/profile',
        state: { isMakingChoices: window.location.pathname === '/realizando-escolhas' },
      })
    }
    return `${PROFILES[profile.role].apiUrl}`
  }

  const handler = event => {
    if (environment === 'production') {
      if (event.origin.includes('plataforma.institutoayrtonsenna.org.br')) {
        setValues(event)
      }
    } else if (environment === 'test') {
      if (!event.origin.includes('hotjar')) {
        setValues(event)
      }
    }
  }

  useEffect(() => {
    if (environment === 'production') {
      TagManager.initialize({ gtmId: 'GTM-N3KXBVM' })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('message', handler)
  })

  const find = useCallback(async () => {
    if (UserService.isLoggedIn() && getSelectedProfile()) {
      const requestUrl = handleProfile(getSelectedProfile())
      await api
        .get(requestUrl, {
          params: { userKeycloakId: UserService.getUserId() },
        })
        .then(async value => {
          setCurrentUser(value.data)
          localStorage.setItem('currentUser', JSON.stringify(value.data))
          if (UserService.isFtdUser()) {
            await FtdService.initIonicaMenu()
          }
        })
        .catch(error => {
          if (error?.response?.status === HttpStatus.NOT_FOUND) {
            setOpen(true)
          }
        })
    }
  }, [])

  const findSpecialNetworkManager = useCallback(async () => {
    await api
      .get(`network-manager-special`)
      .then(value => {
        setIsSpecialNetworkManager(value.data)
      })
      .catch(error => {
        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          setIsSpecialNetworkManager(false)
        }
      })
  }, [])

  const findNetworkManagerAt = useCallback(async () => {
    await api
      .get(`network-manager-at`, {
        params: { userKeycloakId: UserService.getUserId() },
      })
      .then(value => {
        if (value.data) {
          setIsNetworkManagerAt('Agente Técnico')
        } else {
          setIsNetworkManagerAt('')
        }
      })
      .catch(error => {
        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          setIsNetworkManagerAt('')
        }
      })
  }, [])

  const findNetworkManagerFromConexia = useCallback(async () => {
    await api
      .get(`network-managers/isConexia`)
      .then(value => {
        if (value.data) {
          setIsConexia('Conexia')
        } else {
          setIsConexia('')
        }
      })
      .catch(error => {
        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          setIsConexia('')
        }
      })
  }, [])

  const findIsAdministratorFarol = useCallback(async () => {
    await api
      .get(`administrators/is-farol-adm`)
      .then(value => {
        setIsAdmFarol(value.data)
      })
      .catch(error => {
        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          setIsAdmFarol(false)
        }
      })
  }, [])

  const findIsAdministratorFtd = useCallback(async () => {
    await api
      .get(`administrators/is-adm-ftd`)
      .then(value => {
        setIsAdmFtd(value.data)
      })
      .catch(error => {
        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          setIsAdmFtd(false)
        }
      })
  }, [])

  const findAssessmentQuantities = useCallback(async () => {
    if (currentUser !== null && currentUser !== undefined) {
      const isExaminee = getSelectedProfile().role === 'EXAMINEE'
      const url = isExaminee
        ? `user/active-assessments/examinee/${currentUser?.id}`
        : `user/active-assessments`
      await api
        .get(url)
        .then(value => {
          setActiveAssessmentsInfo(value.data)
        })
        .catch(() => {
          setActiveAssessmentsInfo(undefined)
        })
    }
  }, [currentUser])

  const findStartedAssessments = useCallback(async () => {
    if (currentUser !== null && currentUser !== undefined) {
      await api
        .get(`partnership-collaborator/has-started-assessments/${currentUser?.id}`)
        .then(value => {
          setPartCollaboratorHasStartedAssessments(value.data)
        })
        .catch(() => {
          setPartCollaboratorHasStartedAssessments(false)
        })
    }
  }, [currentUser])

  useEffect(() => {
    if (
      window.location.pathname &&
      (window.location.pathname.indexOf('/instrumentoContent') === 0 || isRealizandoEscolhas)
    ) {
      setSignedInPlatform(true)
      setIsHideNavBar(true)
      setIsHideFooter(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkNetWorkManager = async () => {
    findNetworkManagerAt()
    findSpecialNetworkManager()
    findNetworkManagerFromConexia()
  }

  useEffect(() => {
    if (
      UserService.isLoggedIn() &&
      (!getSelectedProfile() || !UserService.hasRole([getSelectedProfile().role]))
    ) {
      removeSelectedProfile()
      history.push({
        pathname: '/profile',
        state: { isMakingChoices: window.location.pathname === '/realizando-escolhas' },
      })
    } else {
      find()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser !== undefined) {
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
      if (UserService.isLoggedIn()) {
        if (
          getSelectedProfile().role === 'NETWORK_MANAGER' ||
          getSelectedProfile().role === 'ADMINISTRATOR'
        ) {
          checkNetWorkManager()
          findIsAdministratorFarol()
          findIsAdministratorFtd()
        }
        if (getSelectedProfile().role !== 'RESEARCHER') {
          if (getSelectedProfile().role === 'PARTNERSHIP_COLLABORATOR') {
            findStartedAssessments()
          } else {
            findAssessmentQuantities()
          }
        }
      }
      setSignedIn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    if (currentUser && UserService.isFtdUser()) {
      const isExaminee = getSelectedProfile()?.role === 'EXAMINEE'
      verifyFtdFirstLogin(setIsTermsAndPolicyOpen, currentUser, isExaminee)
    }
  }, [currentUser])

  const handleClose = () => {
    setOpen(false)
    UserService.forceLogout()
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider
          value={{
            signedIn,
            setSignedIn,
            currentUser,
            setCurrentUser,
            isSpecialNetworkManager,
            isNetworkManagerAt,
            setIsSpecialNetworkManager,
            setIsNetworkManagerAt,
            signedInPlatform,
            closeDrawerMenu,
            setCloseDrawerMenu,
            isConexia,
            isAdmFarol,
            setIsAdmFarol,
            isAdmFtd,
            setIsAdmFtd,
            activeAssessmentsInfo,
            partCollaboratorHasStartedAssessments,
          }}
        >
          <NetworkManagerProvider>
            <ModalContextProvider>
              <LoadingContextProvider>
                <SnackbarContextProvider>
                  <FilterContextProvider>
                    <ApplicationTypeContextProvider>
                      <FilterItemKeyContextProvider>
                        <SidebarProvider history={history}>
                          <ReportTypeContextProvider>
                            <ImportTrackingProvider>
                              <HeaderContextProvider>
                                <BrowserRouter>
                                  <LoadingModal />
                                  <ModalDialog />
                                  {!isHideNavBar && <Navbar history={history} />}
                                  <Paper
                                    id="paperAppId"
                                    elevation={0}
                                    className={signedIn ? classes.rootLogged : classes.root}
                                  >
                                    <main
                                      className={
                                        // eslint-disable-next-line no-nested-ternary
                                        isDesktop
                                          ? isRealizandoEscolhas
                                            ? classes.childrenContainerNoMargin
                                            : classes.childrenContainer
                                          : classes.childrenContainerNoMargin
                                      }
                                    >
                                      <Router history={history}>
                                        <Routes />
                                      </Router>
                                    </main>
                                    {!isRealizandoEscolhas ? <HelpButton /> : null}
                                  </Paper>
                                  {!isHideFooter && <Footer history={history} />}
                                  <CustomSnackbar
                                    open={open}
                                    variant="error"
                                    autoHideDuration={4000}
                                    message="Usuário não encontrado,
                              entre em contato com o administrador do sistema!"
                                    handleClose={handleClose}
                                  />
                                  <TermsAndPolicyDialog
                                    isOpen={isTermsAndPolicyOpen}
                                    setOpen={setIsTermsAndPolicyOpen}
                                    handleAccept={() =>
                                      handleAcceptFtdTermsAndPolicy(
                                        setIsTermsAndPolicyOpen,
                                        currentUser,
                                        getSelectedProfile().role === 'EXAMINEE',
                                      )
                                    }
                                  />
                                </BrowserRouter>
                              </HeaderContextProvider>
                            </ImportTrackingProvider>
                          </ReportTypeContextProvider>
                        </SidebarProvider>
                      </FilterItemKeyContextProvider>
                    </ApplicationTypeContextProvider>
                  </FilterContextProvider>
                </SnackbarContextProvider>
              </LoadingContextProvider>
            </ModalContextProvider>
          </NetworkManagerProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default App
