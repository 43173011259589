import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Header from './Header'
import { useResponsiveInfo } from '../../shared/hooks'
import UserService from '../../services/UserService'

export default function Navbar({ history }) {
  const [ftdMenuCollapsed, setFtdMenuCollapsed] = useState(false)
  const { isMobile } = useResponsiveInfo()

  useEffect(() => {
    const checkBodyClass = () => {
      setFtdMenuCollapsed(document.body.classList.contains('ionicamenu__small'))
    }
    // Initial check
    checkBodyClass()
    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver(checkBodyClass)
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] })
    return () => observer.disconnect()
  }, [])

  const getPaddingLeft = () => {
    if (!UserService.isFtdUser() || isMobile) {
      return 'inherit'
    }
    if (UserService.isFtdUser() && ftdMenuCollapsed) {
      return '63px'
    }
    return '142px'
  }
  const getTopOffset = () => {
    if (!UserService.isFtdUser()) {
      return 'inherit'
    }
    if (UserService.isFtdUser() && isMobile) {
      return '77px'
    }
    return '35px'
  }

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: '#00000033 1px 1px 10px 1px',
      height: '90px',
      paddingLeft: getPaddingLeft(),
      top: getTopOffset(),
      background: 'white',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      minHeight: '14px',
    },
  }))

  const classes = useStyles()
  return (
    <AppBar position="fixed" className={classes.root}>
      <Header history={history} />
    </AppBar>
  )
}
